const theme = {
  config: {
    useLocalStorage: false,
  },
  breakpoints: ["40em", "52em", "64em", "81em"],
  fonts: {
    body: "'Readex Pro', sans-serif",
    heading: "'Readex Pro', sans-serif",
  },
  fontSizes: {
    xs: 16,
    sm: 18,
    md: 24,
    lg: 36,
    xl: 48,
  },
  space: {
    xs: 8,
    sm: 16,
    md: 32,
    lg: 64,
    xl: 128,
  },
  fontWeights: {
    body: 300,
    heading: 600,
    bold: 500,
  },
  colors: {
    text: "#5c5c5c",
    background: "#f5eae4",
    primary: "#006ee3",
    secondary: "#fff",
    accent: "#1E3F63",
    highlight: "#fff",
    muted: "#f8f8f8",
    error: "#bf1646",
  },
  text: {
    bold: {
      fontWeight: "bold",
    },
    error: {
      color: "error",
    },
    subheading: {
      fontWeight: "bold",
      fontSize: "md",
    },
  },
  forms: {
    label: {
      flexDirection: "column",
      fontWeight: "bold",
      mt: "sm",
    },
    checkboxLabel: {
      lineHeight: "1.5",
    },
    input: {
      padding: "sm",
    },
    textarea: {
      padding: "sm",
    },
    checkbox: {
      ":focus": {
        backgroundColor: "secondary",
      },
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      bg: "primary",
      color: "secondary",
      py: "xs",
      px: "md",
    },
    primaryLarge: {
      cursor: "pointer",
      bg: "primary",
      color: "secondary",
      fontSize: "md",
      py: "sm",
      px: "lg",
    },
    error: {
      cursor: "pointer",
      bg: "error",
      color: "secondary",
      py: "xs",
      px: "md",
    },
    accent: {
      cursor: "pointer",
      bg: "accent",
      color: "secondary",
      py: "xs",
      px: "md",
    },
    menu: {
      color: "secondary",
      height: "64px",
      width: "32px",
      ml: "md",
      svg: {
        height: "32px",
        width: "32px",
      },
    },
  },
  layout: {
    container: {
      py: ["md", null, "lg"],
      px: ["md", null, "lg", "xl"],
      bg: "secondary",
    },
    muted: {
      py: ["md", null, "lg"],
      px: ["md", null, "lg", "xl"],
      bg: "muted",
    },
    page: {
      width: "100%",
      maxWidth: "1300px",
      mx: "auto",
      bg: "secondary",
    },
    banner: {
      pt: ["sm", null, 0],
      pb: ["md", null, "lg"],
      px: ["md", null, "xl"],
      bg: "muted",
    },
  },
  links: {
    nav: {
      ml: [0, null, "sm", null, "md"],
      py: "xs",
      fontWeight: ["body", null, "heading"],
      textTransform: "uppercase",
      color: ["secondary", null, "text"],
      ":hover, :focus, :active": {
        color: ["secondary", null, "primary"],
        fontWeight: "heading",
      },
    },
    card: {
      color: "text",
      textDecoration: "none",
      ":hover": {
        color: "primary",
      },
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxWidth: "522px",
      mx: "auto",
      wordBreak: "break-word",
    },
  },
  images: {
    circle: {
      display: "block",
      borderRadius: "50%",
    },
    curved: {
      display: "block",
      borderRadius: "10px",
      mx: "auto",
      width: "100%",
    },
    thumb: {
      display: "block",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: "xs",
    },
    h1: {
      fontSize: ["lg", null, "xl"],
      mt: 0,
      mb: "md",
    },
    h2: {
      fontSize: ["md", null, "lg"],
      mt: 0,
      mb: "md",
    },
    h3: {
      fontSize: ["sm", null, "md"],
      mt: 0,
      mb: "md",
    },
    p: {
      fontSize: "sm",
      mt: 0,
      mb: "md",
    },
    a: {
      color: "primary",
      textDecoration: "underline",
      ":hover": {
        color: "text",
        textDecoration: "none",
      },
    },
    li: {
      fontSize: "sm",
      py: "sm",
      listStyleImage: "url('/caret.svg')",
    },
  },
};

export default theme;
