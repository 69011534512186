import { useEffect } from "react";
import { useRouter } from "next/router";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  useFirebaseApp,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  AnalyticsProvider,
  FunctionsProvider,
  AppCheckProvider,
} from "reactfire";
import { ThemeProvider } from "theme-ui";
import { Global, css } from "@emotion/react";
import { DefaultSeo } from "next-seo";
import theme from "../utils/theme";
import "../style/tabs.css";
import Head from "next/head";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

const POST_HOG_KEY = "phc_5vJmVUTtiynUvGOJCf6gFth3D0OUoyjkmPNDBDODVn8";
if (typeof window !== "undefined") {
  posthog.init(POST_HOG_KEY, {
    api_host: "https://eu.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

const globalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;500;600&display=swap");

  html,
  body,
  #__next {
    height: 100%;
  }
`;

const APP_CHECK_TOKEN = "6LeMM_YjAAAAACS2W2kwORgXrDCmEnIGDsN8sYAj";

const firebaseConfig = {
  apiKey: "AIzaSyA89Jiab1pucB-mZzu3Je_jxHdYD5_MzkI",
  authDomain: "treats-70ee5.firebaseapp.com",
  projectId: "treats-70ee5",
  storageBucket: "treats-70ee5.appspot.com",
  messagingSenderId: "687468196722",
  appId: "1:687468196722:web:14da86ff4132d1bb3423eb",
  measurementId: "G-E3DF3QMLM4",
};

const AppCheckWrapper = ({ children }) => {
  if (typeof window === "undefined") {
    return children;
  }

  const app = useFirebaseApp();

  if (process.env.NODE_ENV === "development") {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  return <AppCheckProvider sdk={appCheck}>{children}</AppCheckProvider>;
};

const FirebaseInitialiser = ({ children }) => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const functions = getFunctions(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <FunctionsProvider sdk={functions}>{children}</FunctionsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
};

const AnalyticsWrapper = ({ children }) => {
  if (typeof window === "undefined") {
    return children;
  }

  const app = useFirebaseApp();
  const analytics = getAnalytics(app);
  const router = useRouter();

  const logPageView = () => {
    logEvent(analytics, "page_view", { page_location: location.href });
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", logPageView);
    return () => {
      router.events.off("routeChangeComplete", logPageView);
    };
  }, [router.events]);

  return <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>;
};

const App = ({ Component, pageProps }) => (
  <>
    <Head>
      <link rel="icon" href="/favicon.png" />
    </Head>
    <DefaultSeo
      title="Treats Children Charity Derby"
      description="Bringing a smile to underprivilaged children in Derby and Derbyshire."
    />
    <PostHogProvider client={posthog}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <AppCheckWrapper>
          <FirebaseInitialiser>
            <AnalyticsWrapper>
              <ThemeProvider theme={theme}>
                <Global styles={() => globalStyles} />
                <Component {...pageProps} />
              </ThemeProvider>
            </AnalyticsWrapper>
          </FirebaseInitialiser>
        </AppCheckWrapper>
      </FirebaseAppProvider>
    </PostHogProvider>
  </>
);

export default App;
